// create a component that will display the results of the text based exam with our correct answers and the user's answers
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import configAxios from "../../axios.config";

export default function TextBasesResults() {
  const { examId } = useParams();
  const [results, setResults] = useState();

  useEffect(() => {
    configAxios(localStorage.getItem("token"))
      .get(`txbq/get-text-based-result/${examId}`)
      .then(({ data }) => {
        setResults(data.answer);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <div
      style={{
        backgroundColor: "#f5f5f5",
        padding: "20px",
      }}
    >
      <h1 style={{ fontSize: "2em", marginTop: "20px", marginBottom: "20px" }}>Your Answers</h1>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr",
          gap: "20px",
        }}
      >
        {results &&
          results.map((item, index) => (
            <div
              style={{
                backgroundColor: "#fff",
                borderRadius: "5px",
                padding: "20px",
                boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
                overflow: "hidden",
                display: "flex",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "black",
                  borderRadius: "50%",
                  width: "30px",
                  height: "30px",
                  marginRight: "20px",
                }}
              >
                {index + 1}.
              </div>
              <div style={{ fontSize: "1.2em" }}>
                <h2 style={{ marginBottom: "10px", fontSize: "1.5em" }}>{item.questionId.question}</h2>
                <div>
                  <p style={{ marginBottom: "5px" }}>
                    <strong>Your answer:</strong>
                  </p>
                  <p style={{ marginBottom: "10px" }} dangerouslySetInnerHTML={{ __html: item.answer ? item.answer : "Not answered" }}></p>
                  <p style={{ marginBottom: "5px" }}>
                    <strong>Correct answer:</strong>
                  </p>
                  <p style={{ marginBottom: "0", color: "green" }}>{item.questionId.answer}</p>
                  <div style={{ display: "flex", marginTop: "10px", gap: "5px" }}>
                    <p style={{ marginBottom: "5px" }}>
                      <strong>Score:</strong>
                    </p>
                    <p style={{ marginBottom: "0" }}>{item.score}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
