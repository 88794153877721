import { AxiosResponse } from 'axios';
import { changeTimer, updateQuizData, updateCodingData } from "../../app/hybridExam/actions/action";
import { getExamProgress, deleteProgress, completeQuizExam } from './UtilsAPI';
import { HideWebCamStyled } from "../../components/styled/HideWebcam.styled";
import { QUERY_KEY } from '../../constants/querykey';
import { TParticularExamDataHybridResponse } from './types';
import { useEffect, useRef, useState, MutableRefObject, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useSelector, useDispatch } from 'react-redux';
import { useTimer } from "react-timer-hook";
import Button from 'react-bootstrap/Button';
import CodingExam from './CodingExam'
import CodingInstruction from './CodingInstruction';
import configAxios from "../../axios.config";
import MainInstruction from './MainInstruction';
import moment from "moment";
import QuizExam from "./QuizExam";
import QuizsInstruction from './QuizsInstruction';
import Spinner from 'react-bootstrap/Spinner';
import Webcam from 'react-webcam';
import { HYBRID_ACTIVE_TABS } from '../../app/hybridExam/reducers/reducers';
import ParagraphInstructions from './ParagraphInstructions';
import TextBasedAnswers from './TextBasedAnswers';

const defualt_timer = new Date(2030, 1, 1, 1, 1, 1, 1);
defualt_timer.setSeconds(defualt_timer.getSeconds())


function SpinnerButton() {
  return (
    <div className="my-2 mx-3">
      <Button variant="primary" disabled>
        <Spinner
          as="span"
          animation="grow"
          role="status"
          aria-hidden="true"
        />
        Loading...
      </Button>
    </div>
  )
}

interface INewHybridExam {
  hidenavbar: (val: boolean) => void
}
function NewHybridExam({ hidenavbar }: INewHybridExam) {

  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const activeTab: number = useSelector(({ hybridCompiler }: IRootState) => hybridCompiler.activeTab);
  const codingUpdateData = useSelector(({ hybridCompiler }: IRootState) => hybridCompiler.codingExamData);
  const quizUpdateData = useSelector(({ hybridCompiler }: IRootState) => hybridCompiler.quizExamData);
  const paragraphUpdateData = useSelector(({ hybridCompiler }: IRootState) => hybridCompiler.paragraphExamData);

  const [arrayTabs, setArrayTabs] = useState<string[]>([]);
  const [userData, setUserData] = useState<{
    userDP: string;
    userName: string;
    userId: string;
    email: string;
  }>();
  const [timing, setTiming] = useState<number>(1);
  const [examData, setExamsData] = useState<{
    attempts: number;
    category: string | undefined;
    company: string | undefined;
    courseId: string;
    description: string;
    examName: string;
    questions: Array<IQuizQuestion>;
    timer: string;
    typeOfExam: string;
    _id: string;
  }>();
  const [codeExamData, setCodeExamData] = useState<ICodeExamData>();
  const [courseId, setCourseId] = useState<string | undefined>();
  const [displayResult, setDisplayResult] = useState<boolean>(true);
  const [displayWebCam, setDisplayWebCam] = useState<boolean>(false);
  const [isBlackboxDetected, setIsBlackboxDetected] = useState(false);
  const [isCameraOn, setIsCamerOn] = useState(false);
  const [isWebSocketConnected, setIsWebSocketConnected] = useState<boolean>(false);
  const [numberofTabs, setnumberofTabs] = useState(0);
  const [questionsArray, setQuestionsArray] = useState<Array<IQuizQuestion>>([]);
  const [paragraphQuestionArray, setParagraphQuestionArray] = useState<Array<{ _id: string; question: string; marks: number }>>([]);
  const [unquestions_Attempted, setUnQuestionsAttempted] = useState<Array<IQuizQuestion>>([]);

  const webcamRef: MutableRefObject<Webcam | null> = useRef<Webcam>(null);

  const webcamCallback = useCallback<(node: Webcam) => unknown>((node) => {
    if (!node || !node.video) return;
    webcamRef.current = node;
  }, []);

  const Timer = useTimer({
    expiryTimestamp: defualt_timer,
    onExpire: submithandler
  })

  // use queries
  const { data: hybridExamData, error: hybridExamError } = useQuery([QUERY_KEY.getParticularExamHybrid, id], () => {
    return configAxios(localStorage.getItem("token")).get<TParticularExamDataHybridResponse>(`/getparticularexam/${id}`).then(({ data }) => data)
  }, {
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false

  })

  useEffect(() => {
    const checkForPlugin = () => {
      if (document.getElementsByClassName('blackbox-overlay').length > 0) {
        setIsBlackboxDetected(true);
        clearInterval(interval);
      }
    }
    const interval = setInterval(checkForPlugin, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setnumberofTabs(count => count + 1);
    };

    window.addEventListener('blur', handleVisibilityChange);

    return () => {
      window.removeEventListener('blur', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    let socketTimeout: ReturnType<typeof setTimeout> | null = null;
    if (!id || !userData) return;
    if (isWebSocketConnected) {
      return;
    }

    const live_data_socket = new WebSocket(`wss://g7uvpr5cr9.execute-api.ap-south-1.amazonaws.com/production?examId=${id}&name=${userData.userName}&email=${userData.email}&userId=${userData.userId}`)

    live_data_socket.onopen = function () {
    }

    live_data_socket.onclose = function () {
      setIsWebSocketConnected(true);

      socketTimeout = setTimeout(() => {
        setIsWebSocketConnected(false);
      }, 5000);
    }

    return () => {
      live_data_socket.close();

      if (socketTimeout) {
        clearTimeout(socketTimeout);
      }
    }
  }, [userData, id, isWebSocketConnected])

  useEffect(() => {
    console.log(arrayTabs);
  }, [arrayTabs])

  useEffect(() => {
    if (!id) return;
    if (!hybridExamData) return;
    const controller = new AbortController();
    const signal = controller.signal;

    configAxios(localStorage.getItem("token")).post<{ sessionId: number }>('/create-hybrid-session', {
      examId: id,
      codingExamId: hybridExamData.exam.Codeexam,
      quizExamId: hybridExamData.exam.Quizexam,
      paragraphExamId: hybridExamData.exam.ParagraphExam
    }, {
      signal
    })
      .then(({ data }) => sessionStorage.setItem(`hybrid_exam_${id}`, `${data.sessionId}`))
      .catch(err => {
        console.error("Error creating session Id")
        alert("Error creating session Id");
        window.location.reload();
      });
  }, [id, hybridExamData]);

  useEffect(() => {
    if (!hybridExamData) return;

    if (hybridExamData.exam?.locked === true) {
      history.push("/dashboard/" + hybridExamData.exam.courseId);
    }

    if (hybridExamData.exam.Quizexam) {
      setArrayTabs(["", HYBRID_ACTIVE_TABS.QUIZ_INSTRUCTIONS, HYBRID_ACTIVE_TABS.QUIZ_EXAM]);
    }

    if (hybridExamData.exam.ParagraphExam) {
      setArrayTabs(prev => [...prev, HYBRID_ACTIVE_TABS.PARAGRAPH_INSTRUCTIONS, HYBRID_ACTIVE_TABS.PARAGRAPH_QUESTIONS]);
    }

    if (hybridExamData.exam.Codeexam) {
      setArrayTabs(prev => [...prev, HYBRID_ACTIVE_TABS.CODING_INSTRUCTIONS, HYBRID_ACTIVE_TABS.CODING_EXAM]);
    }

    let display_cam = !!hybridExamData.exam.proctoring?.video;
    setDisplayWebCam(display_cam);
  }, [hybridExamData, history])

  useEffect(() => {
    if (!hybridExamError) return;

    const { response } = hybridExamError as { response: AxiosResponse<any> };
    if (response?.status !== 401) {
      history.push('/profile');
      alert("You have exhausted the number of attempts");
    }
  }, [hybridExamError, history])


  /**
   * * Detect browser reload
   */
  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, []);

  useEffect(() => {
    dispatch(changeTimer(Timer));
  }, [Timer, dispatch]);

  function alertUser(e) {
    e.preventDefault();
    e.returnValue = "Are you sure you want to reload";
  };


  async function submithandler() {
    const convertToMinutes = (hours: number, minutes: number, seconds): number => {
      let total_minutes = hours * 60 + minutes + seconds / 60;
      return parseFloat(total_minutes.toFixed(2));
    }

    let {
      hours,
      minutes,
      seconds
    } = Timer;

    let temp_quiz_results = {
      ...quizUpdateData,
      examId: examData?._id || "",
    }
    let temp_paragraph_results = {
      ...paragraphUpdateData,
      examId: hybridExamData?.exam.ParagraphExam || "",
    }

    let postData = {
      quizresults: temp_quiz_results.examId ? temp_quiz_results : undefined,
      coderesults: codingUpdateData.examId ? codingUpdateData : undefined,
      paragraphresults: temp_paragraph_results.examId ? temp_paragraph_results : undefined,
      examID: id,
      session: sessionStorage.getItem(`hybrid_exam_${id}`),
      timeTaken: timing - convertToMinutes(hours, minutes, seconds),
      noOfTabs: numberofTabs,
      isCameraOn: isCameraOn,
    }
    await configAxios(localStorage.getItem("token")).put(`/updateresult/hybrid-exam/${id}`, postData).then(() => {
      deleteProgress(id).then((data) => {
      })
        .catch((err) => console.error(err))
        .finally(() => {
          if (displayResult) {
            history.push("/exam-result/" + id);
          } else {
            alert("Exam Submitted");
            history.push("/dashboard/" + courseId, {
              tab: 6
            });
          }

        });
    }).catch(err => {
      alert("Failed Submitting the response. Please try again")
      console.error(err.response);
    })
  }

  useEffect(() => {
    if (!examData) return;

    const startTimer = (travelTime: number) => {
      const time = new Date();
      var travelTime_new = moment().unix();
      const newtimer = travelTime - travelTime_new;
      time.setSeconds(time.getSeconds() + newtimer);
      Timer.restart(time);
    };
    getExamProgress(id)
      .then(({ examProgress }) => {
        if (!examProgress) return;
        let minutes = Math.floor(examProgress.timeRemaining);
        let seconds = (examProgress.timeRemaining % 1).toFixed(2);
        const travelTime = moment()
          .add(minutes, "minutes")
          .add(seconds, "seconds")
          .unix();

        startTimer(travelTime);
        if (examProgress.isQuizCompleted) {
          // remove _id from score and review and send the
          const score = examProgress.quizResult.score.map((item: any) => {
            delete item._id;
            return item;
          }
          );
          const review = examProgress.quizResult.review.map((item: any) => {
            delete item._id;
            return item;
          }
          );
          const quizResult = {
            score: score,
            review: review,
            examId: examProgress.quizResult.examId,
            questions_Attempted: examProgress.quizResult.questions_Attempted,
            finalScore: examProgress.quizResult.finalScore || 0
          }
          let code_score = 0;
          examProgress.codingResult.forEach(val => {
            code_score += val.score;
          })
          const codingResult = {
            examId: codeExamData?._id || "",
            scoring: examProgress.codingResult,
            totalScore: code_score,
            noOftabs: 0,
            timeTaken: ""
          }
          dispatch(updateQuizData(quizResult))
          dispatch(updateCodingData(codingResult))
          console.log(arrayTabs)

          setArrayTabs(prev => {
            const arr = [...prev];

            arr.splice(0, 3)

            if (arr.includes(HYBRID_ACTIVE_TABS.PARAGRAPH_QUESTIONS)) {
              dispatch({ type: HYBRID_ACTIVE_TABS.PARAGRAPH_INSTRUCTIONS });
            }

            if (arr.includes(HYBRID_ACTIVE_TABS.CODING_EXAM)) {
              dispatch({ type: HYBRID_ACTIVE_TABS.CODING_INSTRUCTIONS });
            }

            return arr;
          })

          // dispatch({ type: "hybridExam/coding-instructions" });
        }
      })
      .catch((err) => {
        console.log(err);
      });

  }, [examData]);


  useEffect(() => {
    hidenavbar(true);
    return () => {
      hidenavbar(false);
    };
  }, [hidenavbar]);

  /**
   * User data fetched from here
   */
  useEffect(() => {
    if (!history) return;

    const controller = new AbortController();
    let token = localStorage.getItem("token")
    if (!token) {
      history.push("/login?redirect=/hybrid-exam/" + id);
      return;
    }
    configAxios(token).get<IUserData>('/getProfile', {
      signal: controller.signal
    })
      .then(({ data }) => {
        if (!data.user._id) return;
        let userDP = data.user.profile_pic ? data.user.profile_pic : "";
        let userName = (data.user.firstName ? data.user.firstName : "") + " " + (data.user.lastName ? data.user.lastName : "")
        let userId = data.user._id
        let email = data.user.email;
        setUserData({ userDP, userName, userId, email });
      })
      .catch((err) => {
        if (err.name === "CanceledError") return;

        history.push("/login?redirect=/hybrid-exam/" + id);
      });

    return () => controller.abort();
  }, [history, id]);

  useEffect(() => {
    if (!history && id) return;
    if (!userData) return;

    const controller = new AbortController();
    let token = localStorage.getItem("token")
    if (!token) {
      history.push('/login?redirect=/hybrid-exam/' + id);
      return;
    }

    const startTimer = (travelTime: number) => {
      const time = new Date();
      var travelTime_new = moment().unix();
      const newtimer = travelTime - travelTime_new;
      time.setSeconds(time.getSeconds() + newtimer);
      Timer.restart(time);
    };

    configAxios(token).get<IQuizExamData>(`/getHybridExamData/${id}`, {
      signal: controller.signal
    }).then(({ data }) => {
      setCodeExamData(data.exam.Codeexam);
      setExamsData(data.exam.Quizexam);
      setParagraphQuestionArray(data.exam.ParagraphExam?.textBasedQuestions || [])
      setQuestionsArray(shuffleArray(data.exam.Quizexam?.questions || []));
      setTiming(parseInt(data.exam.exam_timer));
      setUnQuestionsAttempted(data.exam.Quizexam?.questions || []);
      if (data.exam.displayResult === false) {
        setDisplayResult(data.exam.displayResult);
        setCourseId(data.exam.courseId);
      }
      const travelTime = moment()
        .add(data.exam.exam_timer, "minutes")
        .unix();

      startTimer(travelTime);
    })
      .catch(({ response }) => console.log(response));
    // })


    return () => controller.abort();


  }, [history, id, userData])

  function shuffleArray(array: Array<IQuizQuestion>): Array<IQuizQuestion> {
    for (var i = array.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  }

  const convertToMinutes = (hours: number, minutes: number, seconds): number => {
    let total_minutes = hours * 60 + minutes + seconds / 60;
    return parseFloat(total_minutes.toFixed(2));
  }

  function onNextClick() {
    setArrayTabs(prev => {
      const arr = [...prev];
      arr.shift();

      if (arr.length === 0) {
        submithandler()
        return arr;
      }

      if (!["", HYBRID_ACTIVE_TABS.QUIZ_INSTRUCTIONS, HYBRID_ACTIVE_TABS.QUIZ_EXAM].includes(arr[0])) {
        let time_rem = (convertToMinutes(Timer.hours, Timer.minutes, Timer.seconds));
        completeQuizExam(id, time_rem);
      }
      dispatch({ type: arr[0] })
      return arr;
    })
  }

  function getActiveTab(activeTab: number): JSX.Element {
    switch (activeTab) {
      case 0:
        return (<MainInstruction onNextClick={onNextClick} />)

      case 1:
        return (<QuizsInstruction onNextClick={onNextClick} />)

      case 2:
        return (
          userData && examData ?
            <QuizExam
              {...userData}
              questionsArray={questionsArray}
              unquestions_Attempted={unquestions_Attempted}
              examData={examData}
              onSubmit={onNextClick}
            /> : <SpinnerButton />
        )

      case 3:
        return (<ParagraphInstructions onNextClick={onNextClick} />)

      case 4:
        return (userData ? <TextBasedAnswers
          {...userData}
          questionsArray={paragraphQuestionArray}
          onSubmit={onNextClick}
        /> : <SpinnerButton />)

      case 5:
        return (<CodingInstruction />)

      case 6:
        return (<CodingExam codingData={codeExamData} onFinish={submithandler} />)

      default:
        return (<div>  </div>)
    }
  }

  if (isBlackboxDetected) {
    return (
      <div className="warning" style={{
        fontSize: '1.5rem',
        fontWeight: 'bold',
        padding: '1rem',
      }}>
        We've detected the Blackbox plugin, which may interfere with your experience on our site.
        Please consider disabling it while using our platform.
      </div>
    );
  }

  return (
    <>
      {displayWebCam && (
        <>
          <HideWebCamStyled>
            <Webcam
              audio={false}
              height={200}
              screenshotFormat="image/jpeg"
              ref={webcamCallback}
              width={300}
              videoConstraints={{
                facingMode: "user",
              }}
              onUserMediaError={() => {
                setIsCamerOn(false);
                alert(
                  "Camera turned off. Your attempt won't be considered. Turn on the camera and refresh the page"
                );
              }}
              onUserMedia={() => {
                setIsCamerOn(true);
              }}
            />
          </HideWebCamStyled>
        </>
      )}
      {
        getActiveTab(activeTab)
      }
    </>
  )
}

export default NewHybridExam